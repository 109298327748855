import React, { useRef, useEffect, useState } from "react";
import bdLogo from "../fonts/logo-bd.png";

import ReactToPrint from "react-to-print";
import { useParams } from "react-router-dom";

import {
  collection,
  doc,
  getDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "../firebaseConfig";

const Musak63 = () => {
  const [data, setdata] = useState(null);
  const [datePart, setDatePart] = useState("");
  const [timePart, setTimePart] = useState("");
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalUnitPrice, setTotalUnitPrice] = useState(0);
  const { id } = useParams();
  const componentRef = useRef();

  const numberFormatter = (number) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };
  useEffect(() => {
    const getData = async () => {
      const singleData = await getDoc(
        doc(collection(db, "SalesEntry"), id)
      ).then((snapshot) => {
        if (snapshot.exists()) {
          setdata(snapshot.data());
          if (snapshot.data().issueDate) {
            const parts = snapshot.data().issueDate.split(" ");
            const date = parts[0]; // "8/6/2023"
            //const time = parts[1];
            const time = parts[1] + parts[2]; // "11:48:12 AM"

            setDatePart(date);
            setTimePart(time);
          }
          let quantityTotal = 0;
          let unitPriceTotal = 0;

          if (snapshot.data().productList) {
            snapshot.data().productList.forEach((item) => {
              quantityTotal += parseFloat(item.quantity) || 0;
              unitPriceTotal += parseFloat(item.unitPrice) || 0;
            });
          }
          setTotalQuantity(quantityTotal);
          setTotalUnitPrice(unitPriceTotal);
        }
      });
    };

    getData();
  }, []);

  const shouldIncludePageBreak = data && data.productList.length > 7;

  return (
    <>
      <div className="py-4 px-10 bg-slate-100 w-full flex justify-between">
        <p className="font-normal text-xl">Preview</p>
        <ReactToPrint
          trigger={() => (
            <button className="bg-[#ff5c8e] text-white rounded-lg px-4 py-2 mx-1 text-xl hover:bg-[#9e103b]">
              Print
            </button>
          )}
          content={() => componentRef.current}
        />
      </div>
      <div
        className="musakReports ml-10 mr-10 mt-3 flex flex-col"
        ref={componentRef}
      >
        <div className="flex w-full justify-between">
          <div className="pt-4 pl-7">
          <img src={bdLogo} className="w-[76px] h-[72px]" />
          </div>
          
          <div>
            <div className="text-center">
              <h1 className="text-[14px] boldFont font-normal pt-2">
                "গণপ্রজাতন্ত্রী বাংলাদেশ সরকার"{" "}
              </h1>
              <p className="tracking-wide text-[17px] text-black font-medium ">
                জাতীয় রাজস্ব বোর্ড
              </p>
              <p className="font-medium text-[14px] boldFont ">
                কর চালানপত্র
              </p>
            </div>

            <div className="text-left ml-[-116px]">
              <p className="text-[14px] tracking-normal pb-1">{`[বিধি ৪০  এর উপ-বিধি (১) এর দফা (গ) ও দফা (চ) দ্রষ্টব্য]`}</p>
              <p className="text-[14px] tracking-normal pb-1">
                নিবন্ধিত ব্যক্তির নামঃ STAR PARTICLE BOARD MILLS LTD
              </p>
              <p className="text-[14px] tracking-wide pb-1">
                নিবন্ধিত ব্যক্তির বিআইএনঃ 000000796-0302
              </p>
              <p className="text-[14px] pb-1">
                চালানপত্র ইস্যুর ঠিকানাঃ{" "}
                <span className="tracking-normal">
                  {data && data.HeadAddress}
                </span>
              </p>
            </div>
          </div>
          <div className="pt-8 w-24">
            <p className="text-center px-[6px] py-[4px] boldFont border border-black text-[12px] ">
              {" "}
              মূসক - ৬.৩{" "}
            </p>
          </div>
        </div>
        {/* <div className="flex flex-col items-baseline justify-between w-[95%] pt-2">
          <p className="text-[15px]">
            চালানপত্র ইস্যুর ঠিকানাঃ Branch- 125 Horipur, Madanpur, Bandar,
            Narayangonj; Bandar PS; Narayanganj-1411; Bangladesh
          </p>
        </div> */}
        <div className="flex w-[95%] pt-8 pl-1 gap-3 text-black">
          {data && (
            <div className="w-[45%] flex text-[13px] gap-3 flex-col items-baseline mr-5 ">
              <p>ক্রেতার নামঃ {data.Customer}</p>
              <p>ক্রেতার বিআইএন (প্রযোজ্য ক্ষেত্রে): {data.BIN}</p>
              <p>ক্রেতার ঠিকানাঃ {data.Address}</p>
              <p className="pb-1">সরবরাহের গন্তব্যস্থলঃ {data.Destination}</p>
              <p>যানবাহনের প্রকৃতি ও নম্বরঃ {data.VehicleNature}</p>
            </div>
          )}
          {data && (
            <div className="w-[50%] pl-20 text-[13px] gap-2 flex flex-col items-baseline text-black">
              <p>চালানপত্র নম্বরঃ {data.InvoiceNum}</p>
              <p>ইস্যুর তারিখঃ {datePart}</p>
              <p>ইস্যুর সময়ঃ {timePart}</p>
            </div>
          )}
        </div>
        <div className="flex w-[98%] pt-5">
          <table className="w-full  text-[12.5px] text-black  border-collapse leading-[1]">
            <thead
              className=" uppercase text-center"
              //style={{ WebkitTextStroke: ".2px black" }}
            >
              <tr>
                <td className="p-1 border border-black">ক্রমিক</td>
                <td className="p-1 border border-black">
                  {" "}
                  পণ্য/সেবার বর্ণনা
                  <br /> (প্রযোজ্য ক্ষেত্রে ব্র্যান্ড
                  <br /> নামসহ)
                </td>
                <td className="p-1 border border-black">
                  {" "}
                  সরবরাহের
                  <br /> একক
                </td>

                <td className="p-1 px-3 border border-black">পরিমাণ</td>
                <td className="p-1 border border-black ">
                  একক
                  <br />
                  &nbsp;মূল্য&nbsp;<sup>১</sup>
                  <br />
                  (টাকায়)
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </td>
                <td className="p-1 px-3 border border-black">
                  মোট মূল্য
                  <br />
                  (টাকায়)
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </td>
                <td className="p-1 border border-black">
                  সম্পূরক <br />
                  <span className="demoFont text-[9.5px]">শুল্কের</span> <br />
                  হার
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </td>
                <td className="p-1 border border-black ">
                  সম্পূরক
                  <br /> <span className="demoFont text-[9.5px]">শুল্কের</span>
                  <br /> পরিমাণ
                  <br />
                  (টাকায়)
                  <br />
                  <br />
                  <br />
                  <br />
                </td>
                <td className="p-1 mt-[-10px] border border-black">
                  মূল্য
                  <br /> সংযো-
                  <br />
                  জন
                  <br /> করের
                  <br /> হার/
                  <br />
                  সুনির্দিষ্ট
                  <br /> কর
                </td>
                <td className="p-[6px] border border-black">
                  মূল্য সংযোজন
                  <br /> কর/সুনির্দিষ্ট
                  <br /> কর এর
                  <br /> পরিমাণ
                  <br />
                  (টাকায়)
                  <br />
                  <br />
                </td>
                <td className="p-1 border border-black">
                  সকল প্রকার{" "}
                  <span className="demoFont text-[9.5px]">শুল্ক</span>
                  <br /> ও করসহ মূল্য
                  <br />
                  <br />
                  <br />
                  <br />
                </td>
              </tr>
            </thead>
            <tbody className="">
              <tr className="text-center">
                <td className="p-1 border border-black">(১)</td>
                <td className="p-1 border border-black">(২)</td>

                <td className="p-1 border border-black">(৩)</td>
                <td className="p-1 border border-black">(৪)</td>
                <td className="p-1 border border-black">(৫)</td>
                <td className="p-1 border border-black">(৬)</td>
                <td className="p-1 border border-black">(৭)</td>
                <td className="p-1 border border-black">(৮)</td>
                <td className="p-1 border border-black">(৯)</td>
                <td className="p-1 border border-black">(১০)</td>
                <td className="p-1 border border-black">(১১)</td>
              </tr>
              {data &&
                data.productList.map((item, index) => (
                  <tr className="text-[14px] tableFont" style={{verticalAlign: 'top' }}>
                    <td className="pb-2 border border-black text-center leading-none pt-1 tableFont1">
                      {index + 1}
                    </td>
                    <td className="pb-2 border border-black text-left pt-[2px] w-[20%] pr-4 tableFont text-[10px] leading-3 tracking-normal">
                      {item.productName}
                    </td>
                    <td className="pb-2 border border-black text-center leading-none pt-[2px] tableFont1">
                      SFT
                    </td>
                    <td className="pl-[2px] pb-2 border border-black text-right leading-none pt-[2.5px] tableFont1">
                      {numberFormatter(item.IssueQty)}
                    </td>
                    <td className="pb-2 border border-black text-right w-[5%] leading-none pt-[2.5px] tableFont1">
                      {numberFormatter(item.unitPrice)}
                    </td>
                    <td className="pb-2 border border-black text-right leading-none pt-[2.5px] tableFont1">
                      {numberFormatter(item.NetAmount)}
                    </td>
                    <td className="pb-2 border border-black text-center leading-none pt-[2.5px] tableFont1">
                      0.00
                    </td>
                    <td className="pb-2 border border-black text-right leading-none pt-[2.5px] tableFont1">
                      0.00
                    </td>
                    <td className="pb-2 border border-black text-center leading-none pt-[2.5px] tableFont1">
                      15.00
                    </td>
                    <td className="pb-2 border border-black text-right leading-none pt-[2.5px] tableFont1">
                      {numberFormatter(0.15 * item.NetAmount)}
                    </td>
                    <td className="pb-2 border border-black text-right leading-none pt-[2.5px] tableFont1">
                      {numberFormatter(item.GrossAmount)}
                    </td>
                  </tr>
                ))}

              <tr className="p-1" >
                <td
                  colSpan={3}
                  className="leading-none align-bottom text-[10px] boldFont border border-black text-right font-medium"
                  
                >
                  সর্বমোট ={" "}
                </td>
                <td className=" border border-black text-right leading-none align-top tableFont1 pb-1 pt-[2px]">
                  {data &&
                    numberFormatter(
                      data.productList.reduce(
                        (total, item) => total + parseFloat(item.IssueQty),
                        0
                      )
                    )}
                </td>
                <td className="p-1 border border-black pb-1"></td>
                <td className=" border border-black text-right tableFont1 pb-1 pt-1" style={{verticalAlign: 'center' }}>
                  {data && numberFormatter(data.totalNetamount)}
                </td>
                <td className="p-1 border border-black"></td>
                <td className=" pr-0 border border-black text-right tableFont1 pb-1 pt-1" style={{verticalAlign: 'center' }}>0.00</td>
                <td className=" border border-black text-right pb-1"></td>
                <td className=" border border-black text-right tableFont1 pb-1 pt-1" style={{verticalAlign: 'center' }}>
                  {data &&
                    numberFormatter(
                      data.productList.reduce(
                        (totalVAT, item) => totalVAT + 0.15 * item.NetAmount,
                        0
                      )
                    )}
                </td>
                <td className=" border border-black text-right tableFont1 pb-1 pt-1" style={{verticalAlign: 'center' }}>
                  {data && numberFormatter(data.totalgrossamount)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {shouldIncludePageBreak && <div className="page-break"></div>}
        <div
          //style={{ WebkitTextStroke: ".05px black" }}
          className="flex flex-col items-baseline justify-between w-[95%] text-[16px] pt-[72px] text-black"
        >
          <p className="pb-2">
            প্রতিষ্ঠান কর্তৃপক্ষের দায়িত্বপ্রাপ্ত ব্যক্তির নামঃ
          </p>
          <p className="pb-2 ">পদবী:</p>
          <p className="pb-2">স্বাক্ষরঃ</p>
          <p className="pb-2">সিল:</p>
        </div>
        <div className="flex flex-col text-[15px] items-baseline justify-between w-[95%] pt-4 text-black">
          <hr className="w-[330px] border-t-[1px] border-gray-600 " />
          <p
            className="pb-1"
            //style={{ WebkitTextStroke: ".2px black" }}
          >
            <sup>১</sup> সকল প্রকার কর ব্যতীত মূল্য";
          </p>
        </div>
      </div>
    </>
  );
};

export default Musak63;
